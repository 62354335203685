<template>
    <v-carousel class="step__carousel" hide-delimiter-background :hide-delimiters="hideDots" style="height: 400px !important;">
        <template v-for="(item, index) in data">
            <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
                <v-row class="flex-nowrap" style="height:100%">
                    <template v-for="(n,i) in columns">
                        <template v-if="(index + i) < data.length">
                            <v-col :key="i">
                                <v-sheet height="100%">
                                    <v-row class="fill-height" align="center" justify="center">
                                        <div class="step__item" :class="{'step__item--placeholder': data[index+i].previews.length === 0}">
                                            <img v-if="data[index+i].previews.length !== 0" v-auth-image="$axios.defaults.baseURL+data[index+i].previews[0].file" height="100%" width="100%" />
                                            <div class="step__item--hover">
                                                <v-btn @click="goTo(data[index+i])" rounded elevation="0" color="#2E4DD457">
                                                    <img src="/img/eye.png" />
                                                    <span>vizualizare</span>
                                                </v-btn>
                                                <v-btn @click="openInEditor(data[index+i])" rounded elevation="0" color="#2E4DD457">
                                                    <img src="/img/pencil.png" />
                                                    <span>editare</span>
                                                </v-btn>
                                                <v-btn @click="saveIn(data[index+i])" rounded elevation="0" color="#2E4DD457">
                                                    <img src="/img/save.png" />
                                                    <span>salveaza in</span>
                                                </v-btn>
                                            </div>
                                            <div class="tags">
                                                <div class="tag" v-for="age_group in data[index+i].age_groups">
                                                    {{ age_group.name }}
                                                </div>
                                                <div class="tag">
                                                    {{ data[index+i].type.name }}
                                                </div>
                                            </div>
                                        </div>
                                    </v-row>
                                </v-sheet>
                            </v-col>
                        </template>
                    </template>
                </v-row>
            </v-carousel-item>
        </template>
        <move-resource-modal v-model="toggleMoveModal" :btnIcon="'save'" :btnIconSize="24" :title="'Salveaza In'" :btnTitle="'Salveaza'" :selectedItem="selectedItem" :moveResource="false" />
    </v-carousel>
</template>
<style lang="scss">
.step__item {
    .tags {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 10px;
        .tag {
            vertical-align: top;
            display: inline-block;
            background-color: #5e70d9;
            font-size: 10px;
            color: #fff;
            border-radius: 6px;
            padding: 2px 5px;
            margin-right: 5px;
        }
    }
}
.step__carousel {
}
.v-carousel__controls {
    bottom: 9npm0px !important;
}
</style>
<script>
import MoveResource from '../../components/molecules/ResourceMoveToModal.vue';
export default {
    props: ['data', 'columns', 'hideDots'],
    components: {
        'move-resource-modal': MoveResource
    },
    data() {
        return {
            toggleMoveModal: false,
            selectedItem: null,
        }
    },
    methods: {
        async openInEditor(item) {
            try {
                let response = await this.$axios.post('/resources/' + item.id + '/save-to-personal');
                this.$router.push('/main/' + this.$account + '/editor/' + response.data.id);
                console.log('res', response);
            } catch (error) {
                console.log(error);
                this.$toasted.error("Server error");
            }
        },
        goTo(item) {
            this.$router.push('/main/' + this.$account + '/resources/' + this.$route.params.resources_id + '/resource/' + item.id)
        },
        saveIn(item) {
            this.selectedItem = item;
            this.toggleMoveModal = true;
        },
    }
}
</script>