<template>
    <div class="template-category" style="margin-top: -15px;">
        <div class="category" v-for="category in data.children">
            <div class="title" style="margin-bottom: 10px; font-size: 14px !important; cursor: pointer;" @click="category.open = !category.open; $forceUpdate();">
                <div class="pull-right">
                    <v-icon>{{ (category.open) ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                </div>
                {{ category.name }}
            </div>
            <div class="children">
                <template-category @select="$emit('select', $event)" :data="category" :key="category.id" v-if="category.children.length > 0 && category.open" />
                <div class="templates" v-if="category.open">
                    <v-row>
                        <!-- Demo First -->
                        <v-col cols="6" v-for="template in category.templates" style="padding: none;" v-if="template.demo == 1">
                            <div @click="$emit('select', template)" class="template-holder" :class="{'premium': template.demo == 0 && !premium}" v-ripple>
                                <img v-if="!template.customfile" :src="'https://api.chartarium.ro/storage/templates' + template.file" alt="" width="100%">
                                <img v-else :src="template.file" alt="" width="100%">
                                <div class="name" v-if="template.demo == 0 && !premium">
                                    PREMIUM
                                </div>
                            </div>
                        </v-col>
                        <!-- Premium After -->
                        <v-col cols="6" v-for="template in category.templates" style="padding: none;" v-if="template.demo == 0">
                            <div @click="$emit('select', template)" class="template-holder" :class="{'premium': template.demo == 0 && !premium}" v-ripple>
                                <img v-if="!template.customfile" :src="'https://api.chartarium.ro/storage/templates' + template.file" alt="" width="100%">
                                <img v-else :src="template.file" alt="" width="100%">
                                <div class="name" v-if="template.demo == 0 && !premium">
                                    PREMIUM
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
        <!-- <div class="category general" v-if="data.templates.length > 0">
            <div class="templates">
                <v-row>
                    <v-col cols="6" v-for="template in data.templates" style="padding: none;">
                        1
                        <div @click="$emit('select', template)" class="template-holder" v-ripple>
                            <img :src="'https://chartarium.weareomni.eu/storage/templates' + template.file"  alt="" width="100%">
                            <div class="name">
                                {{ template.name }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div> -->
    </div>
    </div>
</template>
<style lang="scss">
.templates {
    margin-bottom: 15px;
}

.main-navigation__level-2-editor {
    height: calc(100vh - 95px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.title {
    position: relative;
    width: 100%;
    font-size: 14px !important;

    .pull-right {
        position: absolute;
        right: 0;
    }

}

.template-holder {
    padding: 25px 25px 25px 25px;
    background-color: #DBDCE0;
    border-radius: 6px;
    position: relative;
    cursor: pointer;

    &.premium {
        opacity: 0.3;
        pointer-events: none;
    }

    .name {
        font-size: 10px;
        padding: 3px 0px 0px 0px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        ;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
        border-radius: 0px 0px 6px 6px;

    }

}

.template-category {
    .children {
        margin-top: 20px;
        padding-left: 15px;
        border-left: 2px dashed #cfcfcf;
        margin-left: 5px;
    }
}
</style>
<script>
import TemplateCategory from './TemplateCategory.vue';
import { mapGetters, mapActions } from "vuex";
export default {
    name: 'TemplateCategory',
    props: ['data'],
    components: {
        TemplateCategory,
    },
    computed: mapGetters(["getAccount", "getUser"]),
    data() {
        return {
            premium: false,
        }
    },
    mounted() {
        let currentAccountHash = this.getAccount;
        let user = this.getUser;
        let account = user.accounts.find(e => {
            return (e.hash_id == currentAccountHash);
        });

        if(account.plan.resource_edit) {
            this.premium = true;
        }
    }

}
</script>