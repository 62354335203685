<template>
    <div class="main-navigation">
        <!-- Level 1 menu -->
        <div class="main-navigation__level-1" v-if="data.length === 3" data-aos="fade">
            <div v-for="menuItem in data" :key='menuItem.id' >
                <div class="main-navigation__level-1--title" :class="{'main-navigation__level-1--active':expandedSection===menuItem.name}" @click="expandSection(menuItem)">{{menuItem.name}}</div>
                <transition name='accordion' v-on:before-enter="beforeEnterV" v-on:enter="enterV" v-on:before-leave="beforeLeaveV" v-on:leave="leaveV">
                    <div v-if="menuItem.children" class="main-navigation__level-1--section" v-show="expandedSection===menuItem.name">
                        <div class="main-navigation__level-1--subtitle" :class="{'main-navigation__level-1--open-level-2':(selectedSectionItem?selectedSectionItem.id:null)===item.id}" v-for="item in menuItem.children" :key="item.id" @click="selectSectionItem(item)" v-if="item.name == 'Sistem' || item.name == 'Icon'">
                            <div class="upper-cutout"></div>
                            <div class="lower-cutout"></div>
                            {{item.name}}
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <!-- Level 2 menu -->
        <div class="main-navigation__level-2-editor" v-show="showLevelTwo">
            <div v-if="selectedSectionItem" class="main-navigation__level-2-editor--wrapper">
                <template-category @select="selectEditor($event)" :data="selectedSectionItem" />
            </div>
        </div>
        <div class="close-button--shadow" v-if="selectedSectionItem && selectedSectionItem.name !== 'Page'">
            <div class="close-button--shape" @click="toggleLevelTwo">
                <v-icon color="#D4D9DF">{{showLevelTwo?`mdi-chevron-left`:`mdi-chevron-right`}}</v-icon>
            </div>
        </div>
    </div>
</template>
<script>
import TemplateCategory from './TemplateCategory.vue';
import { mapGetters, mapActions } from "vuex";
export default {
    data() {
        return {
            data: [],
            expandedSection: null,
            selectedSectionItem: null,
            showLevelTwo: true,
        }
    },
    components: {
        TemplateCategory,
    },
    computed: mapGetters(["getAccount", "getUser"]),
    async mounted() {
        await this.getData();
        this.$root.$on('refetchMenu', () => {
            this.expandedSection = null,
                this.selectedSectionItem = null,
                this.expandedSubSection = null,
                this.selectedSubSectionItem = null,
                this.getData()
        });

        this.$root.$on('hideLevelTwo', () => {
            this.showLevelTwo = false;
        });
    },
    beforeDestroy() {
        this.$root.$off('openEditorLevelTwo');
        this.$root.$off('selectEditor');
        this.$root.$off('hideLevelTwo');
    },
    methods: {
        //Expand Root Section
        expandSection(item) {
            this.selectedSectionItem = null;
            if (this.expandedSection === item.name) {
                this.expandedSection = null;
            } else {
                this.expandedSection = item.name;
                this.showLevelTwo = false;
            }
            if (this.expandedSection === this.data[0].name || this.expandedSection === this.data[2].name) {
                this.$root.$emit('openMenu', this.expandedSection);
                this.$router.push(`/main/${this.$account}?menu=${this.expandedSection}`);
            }
        },
        //Select Menu Item in Expanded Root Section
        selectSectionItem(item) {
            this.selectedSectionItem = JSON.parse(JSON.stringify(item));
            this.showLevelTwo = true;
            if (this.selectedSectionItem.name === this.data[1].children[0].name) {
                this.$root.$emit('openEditorLevelTwo', false);
            } else {
                this.$root.$emit('openEditorLevelTwo', true);
            }
            if (this.expandedSection === this.data[2].name) {
                if (item.id !== parseInt(this.$route.params.folders_id)) {
                    this.$router.push('/main/' + this.$account + '/folders/' + item.id);
                }
            }
        },
        selectEditor(item) {
            if (item.type == 'icon') {
                let template = {
                    component: 'v-image',
                    type: 'image',
                    name: 'image1',
                    x: 1000,
                    y: 1200,
                    width: 300,
                    height: 300,
                    stroke: '#000000',
                    opacity: 1,
                    strokeWidth: 0,
                    draggable: true,
                    keepRatio: true,
                    shadowColor: '#000000',
                    shadowBlur: 20,
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    shadowOpacity: 0,
                }
                template.url = "https://api.chartarium.ro/storage/templates" + item.file;
                this.$root.$emit('selectEditor', template)
            } else {
                this.$root.$emit('selectEditor', item);
            }
        },
        // Toggle Level 2 Menu
        toggleLevelTwo() {
            this.showLevelTwo = !this.showLevelTwo;
            this.$root.$emit('openEditorLevelTwo', this.showLevelTwo);
        },
        async getData() {
            try {
                this.data = [];
                let resource = { id: 'resurse', name: 'resurse chartarium', children: [] }
                let editor = {
                    id: 'editor',
                    name: 'editor resurse',
                    children: [{
                        id: 9999999991,
                        name: 'Sistem',
                        parent_id: null,
                        children: [
                            // Text items
                            {
                                //id: 9999999992,
                                name: 'Text',
                                //parent_id: 9999999991,
                                children: [],
                                open: true,
                                templates: [{
                                        //id: 9999999993,
                                        name: 'Titlu',
                                        type: 'text',
                                        component: 'v-text',
                                        file: "/sistem/titlu.svg",
                                        x: 1200,
                                        y: 200,
                                        text: 'Simple Text',
                                        fontSize: 120,
                                        fontFamily: 'Patrick Hand',
                                        verticalAlign: 'left',
                                        fontStyle: 'normal',
                                        textDecoration: '',
                                        fill: '#797778FF',
                                        stroke: '#000',
                                        strokeWidth: 0,
                                        draggable: true,
                                        keepRatio: true,
                                        shadowColor: '#000000',
                                        shadowBlur: 20,
                                        shadowOffsetX: 0,
                                        shadowOffsetY: 0,
                                        shadowOpacity: 0,
                                        demo: 1,
                                    },
                                    {
                                        //id: 9999999994,
                                        name: 'Continut Text',
                                        type: 'text',
                                        component: 'v-text',
                                        fill: 'red',
                                        x: 1200,
                                        y: 600,
                                        file: "/sistem/text.svg",
                                        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam congue eros a ornare mollis. Pellentesque hendrerit elit ac laoreet porta. Nam sit amet ligula id magna egestas mattis. Maecenas eget tortor justo. Suspendisse porta lorem id dictum pretium. Sed fringilla et orci fermentum finibus. Cras tincidunt commodo orci vitae ultricies.',
                                        verticalAlign: 'left',
                                        width: 1200,
                                        fontSize: 90,
                                        fontFamily: 'System',
                                        fontStyle: '',
                                        textDecoration: '',
                                        fill: '#000000FF',
                                        stroke: '#000',
                                        strokeWidth: 0,
                                        draggable: true,
                                        keepRatio: true,
                                        shadowColor: '#000000',
                                        shadowBlur: 20,
                                        shadowOffsetX: 0,
                                        shadowOffsetY: 0,
                                        shadowOpacity: 0,
                                        demo: 1,
                                    },
                                ]
                            },


                        ]
                    }, ]
                }

                // Add personal image if account permits
                let currentAccountHash = this.getAccount;
                let user = this.getUser;
                let account = user.accounts.find(e => {
                    return (e.hash_id == currentAccountHash);
                });
                if (account.plan.edit_logo && account.image) {
                    let logoJSON = {
                        name: 'Logo',
                        children: [],
                        open: true,
                        templates: [{
                            component: 'v-image',
                            type: 'image',
                            name: 'Logo Personal',
                            customfile: true,
                            url: "https://api.chartarium.ro/storage/accounts/" + account.image.split('/')[3],
                            file: "https://api.chartarium.ro/storage/accounts/" + account.image.split('/')[3],
                            x: 1000,
                            y: 1200,
                            width: 1000,
                            height: 1000,
                            stroke: '#000000',
                            opacity: 1,
                            strokeWidth: 0,
                            draggable: true,
                            keepRatio: true,
                            shadowColor: '#000000',
                            shadowBlur: 20,
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            shadowOpacity: 0,
                            demo: 0,
                        }, ]
                    };

                    editor.children[0].children.push(logoJSON);
                }

                





                let response = await this.$axios.get('/template-categories');
                editor.children.push(...response.data);

                console.log('>> editor.children', editor.children);

                let folder = { id: 'dosare', name: 'dosarele mele', children: [] }

                this.data.push(resource);
                this.data.push(editor);
                this.data.push(folder);

                if (this.$route.name === 'Editor Resurse') {
                    this.expandedSection = this.data[1].name;
                    this.selectedSectionItem = this.data[1].children[0];
                }

            } catch (error) {
                console.log(error);
            }


        },

        //Animation functions
        beforeEnterV: function(el) {
            el.style.height = '0';
        },
        enterV: function(el) {
            el.style.height = el.scrollHeight + 16 + 'px';
        },
        beforeLeaveV: function(el) {
            el.style.height = el.scrollHeight + 16 + 'px';
        },
        leaveV: function(el) {
            el.style.height = '0';
        },
        beforeEnterH: function(el) {
            el.style.width = '0';
        },
        enterH: function(el) {
            el.style.width = '290px';
        },
        beforeLeaveH: function(el) {
            el.style.width = '290px';
        },
        leaveH: function(el) {
            el.style.width = '0';
        }
    }
}
</script>