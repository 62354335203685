<template>
    <div>
      <div  v-for="(step) in data" :key="step.key">
        <div class="step" v-if="step.resources.length !==0 ">
          <div class="step__header">
            <h2 class="step__header--heading">Pasul {{step.id}} - <span>{{step.name}}</span></h2>
            <router-link :to="'/main/'+ $route.params.accountId+'/resources/'+$route.params.resources_id+'/level/'+step.id" class="step__header--anchor">
              <span>Vezi mai mult</span>
              <v-icon color="#2E4DD4">mdi-chevron-right</v-icon>
            </router-link>
          </div>
          <carousel :data="step.resources" :columns="carouselItems" :hideDots="hideDots"/>
        </div>
      </div>  
    </div>
</template>
<script>
import carousel from '../molecules/Carousel.vue';
export default {
    components: { carousel },
    props:['data','carouselItems','hideDots'],
    mounted() {
      console.log('>> step data', this.data);
    }
}
</script>