<template>
    <div class="main-navigation">
        <!-- Level 1 menu -->
        <div class="main-navigation__level-1" v-if="data.length === 3" data-aos="fade">
            <div v-for="menuItem in data" :key='menuItem.id' v-if="menuItem.id != 'editor'">
                <div class="main-navigation__level-1--title" :class="{'main-navigation__level-1--active':expandedSection===menuItem.name}" @click="expandSection(menuItem)">{{menuItem.name}}</div>
                <transition name='accordion' v-on:before-enter="beforeEnterV" v-on:enter="enterV" v-on:before-leave="beforeLeaveV" v-on:leave="leaveV">
                    <div v-if="menuItem.children" class="main-navigation__level-1--section" v-show="expandedSection===menuItem.name">
                        <div class="main-navigation__level-1--subtitle" :class="{'main-navigation__level-1--open-level-2':(selectedSectionItem?selectedSectionItem.id:null)===item.id}" v-for="item in menuItem.children" :key="item.id" @click="selectSectionItem(item)">
                            <div class="upper-cutout"></div>
                            <div class="lower-cutout"></div>
                            {{item.name}}
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <!-- Level 2 menu -->
        <div class="main-navigation__level-2" v-show="showLevelTwo" v-if="data.length === 3">
            <div class="main-navigation__level-2--search">
                <v-text-field v-model.trim="filter" placeholder="Search here..." outlined rounded dense prepend-inner-icon="mdi-magnify"></v-text-field>
            </div>
            <div v-if="filteredData.length !== 0">
                <div v-for="menuItem in filteredData[0].children" :key="menuItem.id">
                    <div class="main-navigation__level-2--title" :class="{
                            'main-navigation__level-2--active': 
                            (expandedSection === data[0].name) ? $route.params.resources_id == menuItem.id : 
                            (expandedSection === data[2].name) ? $route.params.folders_id == menuItem.id : null
                        }" @click="expandSubSection(menuItem)">{{menuItem.name}}</div>
                    <transition name='accordion' v-on:before-enter="beforeEnterV" v-on:enter="enterV" v-on:before-leave="beforeLeaveV" v-on:leave="leaveV">
                        <div v-if="menuItem.children" class="main-navigation__level-2--section" v-show="expandedSubSection===menuItem.id">
                            <div v-for="item in menuItem.children" :key="item.id" class="main-navigation__level-2--subtitle" :class="{
                                    'main-navigation__level-2--active': 
                                    (expandedSection === data[0].name) ? $route.params.resources_id == item.id : 
                                    (expandedSection === data[2].name) ? $route.params.folders_id == item.id : null
                                }" @click="navigateTo(item)">{{item.name}}</div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <div class="close-button--shadow" v-if="selectedSectionItem">
            <div class="close-button--shape" @click="toggleLevelTwo">
                <v-icon color="#D4D9DF">{{showLevelTwo?`mdi-chevron-left`:`mdi-chevron-right`}}</v-icon>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            data: [],
            expandedSection: null,
            selectedSectionItem: null,
            expandedSubSection: null,
            selectedSubSectionItem: null,
            showLevelTwo: false,
            filter: '',
        }

    },
    computed: {
        filteredData: function() {
            return this.$_find([this.selectedSectionItem]);
        }
    },
    mounted() {
        this.getData();
        this.$root.$on('refetchMenu', () => {
            this.expandedSection = null,
                this.selectedSectionItem = null,
                this.expandedSubSection = null,
                this.selectedSubSectionItem = null,
                this.getData()
        });
        if (this.$route.query.menu) {
            this.expandedSection = this.$route.query.menu;
        }
    },
    methods: {
        //Expand Root Section
        expandSection(item) {
            this.selectedSectionItem = null;
            if (this.expandedSection === item.name) {
                this.expandedSection = null;
            } else {
                this.expandedSection = item.name;
                this.showLevelTwo = false;
                this.selectedSubSectionItem = null;
            }
            if (this.expandedSection === this.data[1].name) {
                this.expandedSection = null;
                this.$router.push('/main/' + this.$account + '/editor/new');
            }
        },

        //Select Menu Item in Expanded Root Section
        selectSectionItem(item) {
            this.selectedSectionItem = JSON.parse(JSON.stringify(item));
            this.showLevelTwo = true;
            if (this.expandedSection === this.data[2].name) {
                if (item.id !== parseInt(this.$route.params.folders_id)) {
                    this.$router.push('/main/' + this.$account + '/folders/' + item.id);
                }
            }
        },

        //Expand or Navigate Level 2 Menu Item
        expandSubSection(item) {
            if (item.children.length !== 0) {
                if (this.expandedSubSection === item.id) {
                    this.expandedSubSection = null;

                    if (this.expandedSection === this.data[2].name) {
                        if (item.id !== parseInt(this.$route.params.folders_id)) {
                            this.$router.push('/main/' + this.$account + '/folders/' + item.id);
                        }
                    }
                } else {
                    this.expandedSubSection = item.id;
                    if (this.expandedSection === this.data[2].name) {
                        if (item.id !== parseInt(this.$route.params.folders_id)) {
                            this.$router.push('/main/' + this.$account + '/folders/' + item.id);
                        }
                    }
                }
            } else {
                if (this.expandedSection === this.data[0].name) {
                    if (item.id !== parseInt(this.$route.params.resources_id)) {
                        this.$router.push('/main/' + this.$account + '/resources/' + item.id)
                    }
                } else if (this.expandedSection === this.data[2].name) {
                    if (item.id !== parseInt(this.$route.params.folders_id)) {
                        this.$router.push('/main/' + this.$account + '/folders/' + item.id);
                    }
                }
            }
        },

        //Navigation for Level 2 Menu Subitem
        navigateTo(item) {
            if (this.expandedSection === this.data[0].name) {
                if (item.id !== parseInt(this.$route.params.resources_id)) {
                    this.$router.push('/main/' + this.$account + '/resources/' + item.id)
                }
            } else if (this.expandedSection === this.data[2].name) {
                if (item.id !== parseInt(this.$route.params.folders_id)) {
                    this.$router.push('/main/' + this.$account + '/folders/' + item.id);
                }
            }
        },

        // Toggle Level 2 Menu
        toggleLevelTwo() {
            this.showLevelTwo = !this.showLevelTwo;
        },

        //menu search function
        $_find(items) {
            let matches = [];
            if (items[0] !== null) {
                for (let item of items) {
                    if (item.name.toLowerCase().includes(this.filter.toLowerCase())) {
                        matches.push(item);
                    } else if (item.children.length) {
                        let subMatches = this.$_find(item.children);

                        if (subMatches.length) {
                            matches.push({
                                ...item,
                                children: subMatches
                            });
                        }
                    }
                }
            }
            return matches;
        },

        //utility recursive search function
        recursiveSearch(data, matchingItem) {
            for (let t = 0; t < data.length; t++) {
                if (data[t].id === matchingItem) {
                    return data[t];
                } else if (data[t].children.length !== 0) {
                    let result = null;
                    for (let i = 0; result == null && i < data[t].children.length; i++) {
                        result = this.recursiveSearch(data[t].children, matchingItem);
                    }
                    if (result) return result;
                }
            }
            return null;
        },

        //Opens menu to the active folder on window refresh
        getActiveMenu(data, matchingItem) {
            let routeName = matchingItem.name;

            if (routeName.includes('Resource')) {
                this.expandedSection = "resurse chartarium";
                for (let t = 0; t < data.length; t++) {
                    if (data[t].id == matchingItem.params.resources_id) {
                        this.showLevelTwo = true;

                        if (data[t].parent_id === null) {
                            this.selectedSectionItem = data[t]
                        } else {
                            this.selectedSubSectionItem = data[t];
                        }

                        if (data[t].parent_id !== null) {
                            let position = this.recursiveSearch([this.data[0]], data[t].parent_id)

                            if (position.parent_id === null) {
                                this.selectedSectionItem = this.recursiveSearch([this.data[0]], this.selectedSubSectionItem.parent_id);
                            } else {
                                this.expandedSubSection = this.selectedSubSectionItem.parent_id;

                                let parent2 = this.recursiveSearch([this.data[0]], this.selectedSubSectionItem.parent_id);
                                let parent1 = this.recursiveSearch([this.data[0]], parent2.parent_id);
                                this.selectedSectionItem = parent1;
                            }
                        }

                        return data[t];
                    } else if (data[t].children.length !== 0) {
                        let result = null;
                        for (let i = 0; result == null && i < data[t].children.length; i++) {
                            result = this.getActiveMenu(data[t].children, matchingItem);
                        }
                        if (result) return result;
                    }
                }
            }
            if (routeName.includes('Folder')) {
                this.expandedSection = "dosarele mele";
                for (let t = 0; t < data.length; t++) {
                    if (data[t].id == matchingItem.params.folders_id) {
                        this.showLevelTwo = true;

                        if (data[t].parent_id === null) {
                            this.selectedSectionItem = data[t]
                        } else {
                            this.selectedSubSectionItem = data[t];
                        }

                        if (data[t].parent_id !== null) {
                            let position = this.recursiveSearch([this.data[2]], data[t].parent_id)

                            if (position.parent_id === null) {
                                this.selectedSectionItem = this.recursiveSearch([this.data[2]], this.selectedSubSectionItem.parent_id);
                            } else {
                                this.expandedSubSection = this.selectedSubSectionItem.parent_id;

                                let parent2 = this.recursiveSearch([this.data[2]], this.selectedSubSectionItem.parent_id);
                                let parent1 = this.recursiveSearch([this.data[2]], parent2.parent_id);
                                this.selectedSectionItem = parent1;
                            }
                        }

                        return data[t];
                    } else if (data[t].children.length !== 0) {
                        let result = null;
                        for (let i = 0; result == null && i < data[t].children.length; i++) {
                            result = this.getActiveMenu(data[t].children, matchingItem);
                        }
                        if (result) return result;
                    }
                }
            }

            this.$nextTick(() => {
                this.$forceUpdate();
            })
            return null;
        },

        getData() {

            var self = this;
            this.data = [];

            function resources() {
                return self.$axios.get('/resource-categories');
            }

            function folders() {
                return self.$axios.get('/folders');
            }

            Promise.all([resources(), folders()])
                .then(res => {
                    let resource = { id: 'resurse', name: 'resurse chartarium', children: res[0].data }
                    let editor = { id: 'editor', name: '', children: [] }
                    let folder = { id: 'dosare', name: 'dosarele mele', children: res[1].data }

                    this.data.push(resource);
                    this.data.push(editor);
                    this.data.push(folder);


                    if (this.$route.name.includes('Resource')) {
                        this.getActiveMenu([resource], this.$route);
                    }

                    if (this.$route.name.includes('Folder')) {
                        this.getActiveMenu([folder], this.$route);
                    }
                });
        },

        //Animation functions
        beforeEnterV: function(el) {
            el.style.height = '0';
        },
        enterV: function(el) {
            el.style.height = el.scrollHeight + 16 + 'px';
        },
        beforeLeaveV: function(el) {
            el.style.height = el.scrollHeight + 16 + 'px';
        },
        leaveV: function(el) {
            el.style.height = '0';
        },
        beforeEnterH: function(el) {
            el.style.width = '0';
        },
        enterH: function(el) {
            el.style.width = '290px';
        },
        beforeLeaveH: function(el) {
            el.style.width = '290px';
        },
        leaveH: function(el) {
            el.style.width = '0';
        }
    }
}
</script>