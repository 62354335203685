<template>
    <v-dialog
        class="app-modal"
        v-model="show"
        persistent
        max-width="620"
    >
      <v-card class="app-modal__card">
            <div class="app-modal__card--close">
               <inline-svg @click.stop="close()" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4"/>
            </div>
            <v-form ref="form">
            <div class="app-modal__card--content">
                <h1 class="app-modal__title">{{title}}</h1>

                <div class="app-modal__card--folder-title">
                    <v-text-field 
                        v-model="temp.name" 
                        outlined 
                        label="Titlu Dosar"
                        :rules="[() => !!temp.name || 'Campul este obligatoriu']"
                        ref="title"
                    ></v-text-field>
                </div>
                <div class="app-modal__card--folder-breadcrumb" v-if="this.breadcrumb.length!=0">
                    <v-breadcrumbs :items="breadcrumb">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :disabled="true"
                                ripple
                                active-class="app-modal__card--folder-breadcrumb-item"
                            >
                                {{ item.text}}
                            </v-breadcrumbs-item>
                            </template>
                    </v-breadcrumbs>
                </div>

                <!-- Root -->
                <div class="app-modal__card--folder">
                    <v-radio-group class="app-modal__card--folder-block" 
                        row 
                        v-model="temp.parent_id"                 
                        ref="parent_id"
                    >
                        <div class="app-modal__card--folder-item">
                            <div class="app-modal__card--folder-item-content">
                                <v-icon color="#2e4dd4" x-large @click="openRoot">
                                    {{rootFolderOpened ? 'mdi-folder-open':'mdi-folder'}}
                                </v-icon>
                                <v-radio 
                                    color="#00BF78" 
                                    on-icon="mdi-checkbox-outline" 
                                    off-icon="mdi-checkbox-blank-outline" 
                                    value="" 
                                    class="folder-select" 
                                    dense 
                                    hide-details
                                ></v-radio>
                            </div>
                            
                            <p>Dosarele mele</p>
                        </div>
                    </v-radio-group>
                </div>

                <!-- Level 1 -->
                <div class="app-modal__card--folder" v-if="folders && rootFolderOpened">
                    <div class="app-modal__divider"/>
                
                    <v-radio-group class="app-modal__card--folder-block" 
                        row 
                        v-model="temp.parent_id" 
                        ref="parent_id"
                    >
                        <div class="app-modal__card--folder-item" v-for="folder in folders" :key="folder.id">
                            <div class="app-modal__card--folder-item-content">
                                <v-icon color="#2e4dd4" x-large @click="openFolderLvlOne(folder)">
                                    {{lvlOneFolderOpened === folder.id ? 'mdi-folder-open':'mdi-folder'}}
                                </v-icon>
                                <v-radio 
                                    color="#00BF78" 
                                    on-icon="mdi-checkbox-outline" 
                                    off-icon="mdi-checkbox-blank-outline" 
                                    :value="folder.id" 
                                    class="folder-select" 
                                    dense 
                                    hide-details  
                                    ref="lvl_one_folder_id"
                                ></v-radio>
                            </div>
                            
                            <p>{{folder.name}}</p>
                        </div>
                    </v-radio-group>
                </div>

                <!-- Level 2 -->
                <div class="app-modal__card--folder" v-if="lvlOneSelectedFolder">
                    <div class="app-modal__divider"/>
                    
                    <v-radio-group class="app-modal__card--folder-block" 
                        row
                        v-if="lvlOneSelectedFolder.children.length !== 0"
                        v-model="temp.parent_id"                       
                        ref="parent_id"
                    >
                        <div class="app-modal__card--folder-item" v-for="subfolder in lvlOneSelectedFolder.children" :key="subfolder.id">
                            <div class="app-modal__card--folder-item-content">
                                <v-icon color="#2e4dd4" x-large @click="openFolderLvlTwo(subfolder)" :class="{'default-cursor': !moveResource}">
                                    {{lvlTwoFolderOpened === subfolder.id && moveResource ? 'mdi-folder-open':'mdi-folder'}}
                                </v-icon>
                                <v-radio 
                                    color="#00BF78" 
                                    on-icon="mdi-checkbox-outline" 
                                    off-icon="mdi-checkbox-blank-outline" 
                                    :value="subfolder.id" 
                                    class="folder-select" 
                                    dense 
                                    hide-details
                                    ref="lvl_two_folder_id"
                                ></v-radio>
                            </div>
                            
                            <p>{{subfolder.name}}</p>
                        </div>
                    </v-radio-group>
                    <div v-else class="app-modal__card--no-folder">
                        <h4>Dosarul <span>{{lvlOneSelectedFolder.name}}</span> nu contine nicun alt dosar</h4>
                    </div>
                </div>

                <!-- Level 3 -->
                <div class="app-modal__card--folder" v-if="lvlTwoSelectedFolder && moveResource">
                    <div class="app-modal__divider"/>
                    <v-radio-group class="app-modal__card--folder-block" 
                        row 
                        v-model="temp.parent_id"
                        ref="parent_id"
                        v-if="lvlTwoSelectedFolder.children.length !== 0"
                    >
                        <div class="app-modal__card--folder-item" v-for="subfolder in lvlTwoSelectedFolder.children" :key="subfolder.id">
                            <div class="app-modal__card--folder-item-content">
                                <v-icon color="#2e4dd4" x-large>
                                    mdi-folder
                                </v-icon>
                                <v-radio 
                                    color="#00BF78" 
                                    on-icon="mdi-checkbox-outline" 
                                    off-icon="mdi-checkbox-blank-outline" 
                                    :value="subfolder.id" 
                                    class="folder-select" 
                                    dense 
                                    hide-details
                                    ref="lvl_three_folder_id"
                                ></v-radio>
                            </div>
                            
                            <p>{{subfolder.name}}</p>
                        </div>
                    </v-radio-group>
                    <div v-else class="app-modal__card--no-folder">
                        <h4>Dosarul <span>{{lvlTwoSelectedFolder.name}}</span> nu contine nicun alt dosar</h4>
                    </div>
                </div>
                
            </div>
            </v-form>
            <div class="app-modal__card--actions">
                <v-btn class="app-modal__btn" elevation="4" rounded color="#fff" @click.stop="postFolder">
                    <inline-svg :src="'/img/'+btnIcon+'.svg'" :height="btnIconSize" :width="btnIconSize" fill="#2e4dd4"/>
                    <span>{{btnTitle}}</span>
                </v-btn>
            </div>
      </v-card>
    </v-dialog>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
export default {
    props:['title','btnIcon', 'btnIconSize','btnTitle','value','moveResource','editFolder','selectedItem','createFolder'],
    components:{
        'inline-svg':InlineSvg
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        }
    },
    data() {
        return {
            temp:{
                name:null,
                parent_id:""
            },
            selected:null,
            folders:null,
            rootFolderOpened:false,
            lvlOneFolderOpened:null,
            lvlTwoFolderOpened:null,
            lvlOneSelectedFolder:null,
            lvlTwoSelectedFolder:null,
            loading: false,
            breadcrumb:[]
        }
    },
    mounted(){
        this.getFolders();
    },
    watch: {
        selectedItem(newItem){
            if(this.editFolder){
                //console.log(newItem)
                this.recursiveSearch(this.folders, newItem.id)
            }
        },
        'temp.parent_id'(newId,oldId){
            this.breadcrumb = [];
            this.createBreadcrumbs(this.folders, newId);
            this.breadcrumb.reverse();
        }
    },
    methods: {
        close(){
            this.show = false;
            this.rootFolderOpened = false;
            this.lvlOneFolderOpened = null;
            this.lvlTwoFolderOpened = null;
            this.lvlOneSelectedFolder = null;
            this.lvlTwoSelectedFolder = null;
            if(!this.editFolder){
                this.temp={
                    name:null,
                    parent_id:""
                }
            }else {
                this.temp={
                    name:this.selected.name,
                    parent_id:this.selected.parent_id
                }
            }
            
            this.$refs.form.resetValidation();
        },
        openRoot(){
            if(this.rootFolderOpened) {
                this.lvlOneFolderOpened= null;
                this.lvlTwoFolderOpened = null;
                this.lvlOneSelectedFolder = null;
                this.lvlTwoSelectedFolder = null;

                this.checkSelectedFolder(this.$refs.lvl_one_folder_id);
                this.checkSelectedFolder(this.$refs.lvl_two_folder_id);
                this.checkSelectedFolder(this.$refs.lvl_three_folder_id);
            }
            this.rootFolderOpened = !this.rootFolderOpened;
            
        },
        openFolderLvlOne(folder){
            if(this.lvlOneFolderOpened === null){
                this.lvlOneFolderOpened = folder.id;
                this.lvlOneSelectedFolder = folder;
            } else if(this.lvlOneSelectedFolder.id === folder.id){
                this.lvlOneFolderOpened = null;
                this.lvlTwoFolderOpened = null;
                this.lvlOneSelectedFolder = null;
                this.lvlTwoSelectedFolder = null;

                this.checkSelectedFolder(this.$refs.lvl_two_folder_id);
                this.checkSelectedFolder(this.$refs.lvl_three_folder_id);
            } else {
                this.lvlOneFolderOpened = folder.id;
                this.lvlOneSelectedFolder = folder;
                this.lvlTwoFolderOpened = null;
                this.lvlTwoSelectedFolder = null;

                this.checkSelectedFolder(this.$refs.lvl_two_folder_id);
                this.checkSelectedFolder(this.$refs.lvl_three_folder_id);
            }  
        },
        openFolderLvlTwo(folder){
            if(this.lvlTwoFolderOpened === null){
                this.lvlTwoFolderOpened = folder.id;
                this.lvlTwoSelectedFolder = folder;
            } else if(this.lvlTwoSelectedFolder.id === folder.id){
                this.lvlTwoFolderOpened = null;
                this.lvlTwoSelectedFolder = null;

                this.checkSelectedFolder(this.$refs.lvl_three_folder_id);
            } else {
                this.lvlTwoFolderOpened = folder.id;
                this.lvlTwoSelectedFolder = folder;

                this.checkSelectedFolder(this.$refs.lvl_three_folder_id);
            }  
        },
        
        validate(){
            let valid = true;
            if(this.temp.name === null || this.temp.parent_id === null || this.temp.name === undefined || this.temp.parent_id === undefined) {
                valid = false;
            }

            return valid;
        },
        getFolders(){
            this.$axios.get('/folders')
                .then(res=>{
                    this.folders = res.data;
                })
                .catch(err=>{
                    this.$toasted.error(err)
                })
        },
        postFolder(){
        
            this.loading = true;
            if(!this.validate()) {
               this.$toasted.error('Toate campurile trebuiesc completate');
               this.loading = false;
               return; 
            }

            if(this.createFolder){
                this.$axios.post('/folders', this.temp)
                .then(res=>{
                    this.$root.$emit('refetchMenu',res);
                    this.$root.$emit('refetchFolders');
                    this.show = false;
                    this.$toasted.success('Dosarul a fost creat');
                    this.close();
                    this.getFolders();
                })
                .catch(err=>{
                    this.$toasted.error(err)
                    this.show = false;
                })
            }
            
            if(this.editFolder){
                this.$axios.put('/folders/'+this.selectedItem.id, this.temp)
                .then(res=>{
                    this.$root.$emit('refetchMenu',res);
                    this.$emit('refetchFolder');
                    this.show = false;
                    this.$toasted.success('Dosarul a fost updatat');
                    this.close();
                    this.getFolders();
                })
                .catch(err=>{
                    this.$toasted.error(err)
                    this.show = false;
                })
            }
            
        },
        recursiveSearch(data, matchingItem){
            for(let t=0;t<data.length;t++){
                if(data[t].id == matchingItem){
                    this.selected = JSON.parse(JSON.stringify(data[t]));
                    this.temp.name = this.selected.name;
                    if(this.selected.parent_id!==null){
                        this.temp.parent_id = this.selected.parent_id;
                    } else {
                        this.temp.parent_id = ""
                    }
                    return data[t];
                } else if (data[t].children.length !==0){
                    let result = null;
                    for(let i=0; result == null && i < data[t].children.length; i++){
                        result = this.recursiveSearch(data[t].children, matchingItem);
                    }                    
                    if(result) return result;
                }
            }
            return null;           
        },
        createBreadcrumbs(data, matchingItem){
            console.log(">> data", data);
            console.log(">> mi", matchingItem);
            /*for(let t=0;t<data.length;t++){
                if(data[t].id == matchingItem){
                    //console.log(data[t]);
                    this.breadcrumb.push({text:data[t].name});
                    if(data[t].parent_id != null){
                        //console.log(data[t].id, data[t].parent_id)
                        this.createBreadcrumbs(this.folders, data[t].parent_id)
                    }
                    if (data[t].parent_id == null){
                         this.breadcrumb.push({text:"Dosarele mele"});
                    }
                    //return data[t];                   
                }  else if (data[t].children.length !==0){
                    let result = null;
                    for(let i=0; result == null && i < data[t].children.length; i++){
                        result = this.createBreadcrumbs(data[t].children, matchingItem);
                    }                    
                    if(result) return result;
                }
            }
            return null;*/
        },
        checkSelectedFolder(arr){
            if(arr != undefined || arr != null){
                arr.forEach(element => {
                    if(element.isActive === true){
                        this.temp.parent_id = null;
                    }
                    return; 
                });
            }
        }
    }

}
</script>