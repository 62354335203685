<template>
    <div class="resources" v-if="this.data && this.filters && this.controlPanelData" data-aos="fade">
        <control-panel :data="this.controlPanelData"/>
        <div class="mb-2">
            <h2 class="step__header--heading">Pasul {{level.id}} - <span>{{level.name}}</span></h2>
        </div>
        <div class="resources__filters">
            <filter-toggle :filterName="'Categorie varsta'" :filter="this.filters.ages" @addFilter="filterBy($event, 'age_group_id')"/>
            <filter-toggle :filterName="'Colectie'" :filter="this.filters.collections" @addFilter="filterBy($event, 'collection_id')"/>
            <filter-toggle :filterName="'Tip document'" :filter="this.filters.types" @addFilter="filterBy($event, 'type_id')"/>
        </div>
        <div class="resources__divider"/>     
        <div class="resources__all-level" v-if="this.data.data.length !== 0">
            <v-row>
                <v-col class="step" v-for="item in this.data.data" :key="item.id" cols="4">
                    <div class="step__item" :class="{'step__item--placeholder':item.previews.length === 0}">
                        <!-- <div style="background-color: green;" v-if="item.system">{{ item.system }}</div> 
                        <div style="background-color: red;" v-if="!item.system">{{ item.system }}</div>  -->
                        <img v-if="item.previews.length !== 0" v-auth-image="$axios.defaults.baseURL+item.previews[0].file" />
                        <div class="step__item--hover">
                            <v-btn @click="goTo(item)" rounded elevation="0" color="#2E4DD457">
                                <img src="/img/eye.png"/>
                                <span>vizualizare</span>
                            </v-btn>
                            <v-btn @click="openInEditor(item)" rounded elevation="0" color="#2E4DD457">
                                <img src="/img/pencil.png"/>
                                <span>editare</span>
                            </v-btn>
                            <v-btn @click="saveIn(item)" rounded elevation="0" color="#2E4DD457">
                                <img src="/img/save.png"/>
                                <span>salveaza in</span>
                            </v-btn>
                        </div>      
                    </div>
                </v-col>
            </v-row>
            <v-pagination
                v-if="data.last_page>1"
                v-model="page"
                :length="data.last_page"
            ></v-pagination>
        </div>
        <move-resource-modal v-model="toggleMoveModal" :btnIcon="'save'" :btnIconSize="24" :title="'Salveaza In'" :btnTitle="'Salveaza'" :selectedItem="selectedItem" :moveResource="false" />
    </div>
</template>
<script>
import ControlPanel from '../../components/organisms/ControlPanel.vue';
import FilterToggle from '../../components/molecules/FilterToggle.vue';
import MoveResource from '../../components/molecules/ResourceMoveToModal.vue';
export default {
     components:{
        'control-panel':ControlPanel,
        'filter-toggle':FilterToggle,
        'move-resource-modal': MoveResource
    },
    data(){
        return {

            toggleMoveModal: false,
            selectedItem: null,

            page:null,
            data:null,
            controlPanelData:null,
            filters:null,
            params:{
                age_group_id:null,
                collection_id:null,
                type_id:null,
            }
        }
    },
    mounted(){
        this.getResourcesByLevel();
        this.getFilters();
        this.getResourceName();
    },
    computed: {
        level: function() {
            let level = this.controlPanelData.levels.find(el=>el.id == this.$route.params.level_id); 
            return level;
        }
    },
    methods: {
        async openInEditor(item) {
            try {
                let response = await this.$axios.post('/resources/' + item.id + '/save-to-personal');
                this.$router.push('/main/' + this.$account + '/editor/' + response.data.id);
                console.log('res', response);
            } catch (error) {
                console.log(error);
                this.$toasted.error("Server error");
            }
        },
        saveIn(item) {
            this.selectedItem = item;
            this.toggleMoveModal = true;
        },
        getResourceName(){
            this.$axios.get('/resource-categories/'+this.$route.params.resources_id)
                .then(res=>{
                    this.controlPanelData = res.data;
                })
                .catch(err=>{
                    this.$toasted.error(err)
                })
        },
        getResourcesByLevel(params){
            this.$axios.get('/resource-categories/'+this.$route.params.resources_id+'/level/'+this.$route.params.level_id,{ params:params })
                .then(res=>{
                    this.data = res.data;
                    this.page = this.data.current_page;
                })
                .catch(err=>{
                    this.$toasted.error(err)
                })
        },
         getFilters(){
            var self = this;
            function types(){
                return self.$axios.get('/resource-types');
            }

            function collections(){
                return self.$axios.get('/resource-collections');
            }

            function ageGroups(){
                 return self.$axios.get('/resource-age-groups');
            }

            Promise.all([types(), collections(), ageGroups()])
                    .then(res=>{
                        this.filters = {};
                        this.filters.types = res[0].data;
                        this.filters.collections = res[1].data;
                        this.filters.ages = res[2].data;
                    });

        },
        filterBy(id,property){
            this.params[property] = id;
            for(const key in this.params){
                if (this.params[key] === null){
                    delete this.params[key];
                }
            } 
            this.getResourcesByLevel(this.params) 
        },
        goTo(item){
            this.$router.push('/main/'+this.$account+'/resources/'+this.$route.params.resources_id+'/resource/'+item.id)
        }
    }
}
</script>