var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view editor"},[_c('div',{staticClass:"bar options"},[_c('div',{staticClass:"type"},[_vm._v(" "+_vm._s(_vm.selected.type)+" ")]),_c('div',{staticClass:"type-options"},[(_vm.selected && _vm.selected.type == 'rectangle')?_c('rectangle-options',{attrs:{"selected":_vm.selected}}):_vm._e(),(_vm.selected && _vm.selected.type == 'circle')?_c('circle-options',{attrs:{"selected":_vm.selected}}):_vm._e(),(_vm.selected && _vm.selected.type == 'star')?_c('star-options',{attrs:{"selected":_vm.selected}}):_vm._e(),(_vm.selected && _vm.selected.type == 'polygon')?_c('polygon-options',{attrs:{"selected":_vm.selected}}):_vm._e(),(_vm.selected && _vm.selected.type == 'text')?_c('text-options',{ref:"textOptions",attrs:{"selected":_vm.selected}}):_vm._e(),(_vm.selected && _vm.selected.type == 'image')?_c('image-options',{attrs:{"selected":_vm.selected}}):_vm._e(),(_vm.selected && _vm.selected.type == 'line')?_c('line-options',{attrs:{"selected":_vm.selected}}):_vm._e()],1),(_vm.selected)?_c('div',{staticClass:"general-options"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.duplicateItem(_vm.selected)}}},'v-icon',attrs,false),on),[_vm._v("mdi-content-copy")])]}}],null,false,1690880759)},[_c('span',[_vm._v("Duplicare")])]),_c('v-menu',{attrs:{"offset-y":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),Object.assign({}, tooltip, menu)),[_vm._v(" mdi-layers-outline ")])]}}],null,true)},[_c('span',[_vm._v("Ordine")])])]}}],null,false,2631763907)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.moveItemZ(_vm.selected, 'top')}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-double-up")]),_vm._v(" Fața ")],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.moveItemZ(_vm.selected, 'front')}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-up")]),_vm._v(" Sus ")],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.moveItemZ(_vm.selected, 'back')}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-down")]),_vm._v(" Jos ")],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.moveItemZ(_vm.selected, 'bottom')}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-double-down")]),_vm._v(" Spate ")],1)],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.deleteItem(_vm.selected)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,false,1556591137)},[_c('span',[_vm._v("Şterge")])])],1):_vm._e()]),_c('div',{ref:"scene",staticClass:"scene"},[_c('v-stage',{ref:"stage",staticClass:"stage",attrs:{"config":_vm.stageConfig},on:{"mousedown":_vm.handleStageMouseDown,"touchstart":_vm.handleStageMouseDown,"dragend":_vm.handleDragEnd}},[_c('v-layer',{ref:"layer"},[_vm._l((_vm.scene),function(item){return _c(item.component,{key:item.id,tag:"component",attrs:{"config":item},on:{"transformend":_vm.handleTransformEnd}})}),(_vm.$refs.stage)?_c('v-transformer',{ref:"transformer",attrs:{"config":{flipEnabled: false}}}):_vm._e()],2)],1)],1),_c('resource-save-to-folder',{attrs:{"btnIcon":'save',"btnIconSize":24,"title":'Creeaza Dosar',"btnTitle":'Salveaza',"moveResource":true,"postData":{ scene: JSON.stringify(_vm.scene), 
                     render: _vm.render}},model:{value:(_vm.showSaveModal),callback:function ($$v) {_vm.showSaveModal=$$v},expression:"showSaveModal"}}),_c('v-dialog',{staticClass:"app-modal",staticStyle:{"z-index":"99"},attrs:{"persistent":"","max-width":"720"},model:{value:(_vm.showCloseModal),callback:function ($$v) {_vm.showCloseModal=$$v},expression:"showCloseModal"}},[_c('v-card',{staticClass:"app-modal__card"},[_c('div',{staticClass:"app-modal__card--close"},[_c('inline-svg',{attrs:{"src":"/img/modal-close.svg","height":"24","width":"24","fill":"#2e4dd4"},on:{"click":function($event){$event.stopPropagation();_vm.showCloseModal = false}}})],1),_c('v-form',{ref:"form"},[_c('div',{staticClass:"app-modal__card--content",staticStyle:{"font-size":"24px"}},[_c('h1',{staticClass:"app-modal__title"},[_vm._v("Inchide Resursa")]),_c('br'),_vm._v(" Doresti sa salvezi aceasta resursa inainte sa inchizi?"),_c('br'),_c('br')])]),_c('div',{staticClass:"app-modal__card--actions"},[_c('v-btn',{staticClass:"app-modal__btn",staticStyle:{"margin-right":"25px"},attrs:{"elevation":"4","rounded":"","color":"#fff"},on:{"click":function($event){$event.stopPropagation();return _vm.confirmClose.apply(null, arguments)}}},[_c('span',[_vm._v("Nu")])]),_c('v-btn',{staticClass:"app-modal__btn",attrs:{"elevation":"4","rounded":"","color":"#465ad3"},on:{"click":function($event){$event.stopPropagation();return _vm.confirmSaveAndClose.apply(null, arguments)}}},[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v("Da")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }